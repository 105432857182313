import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import cn from 'classnames'

import styles from './FAQ.module.scss'

const FAQ = ({ children }) => {
  return <div className={styles.faq}>{children}</div>
}

const Question = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <button
      className={cn(styles.question, { [styles.open]: isOpen })}
      onClick={() => setIsOpen(!isOpen)}
    >
      {children}
      <FontAwesomeIcon
        icon={faChevronDown}
        rotation={isOpen ? 180 : undefined}
        size="1x"
      />
    </button>
  )
}

const Answer = ({ children }) => {
  return <p className={styles.answer}>{children}</p>
}

export { FAQ, Question, Answer }
