import React from 'react'
import { Link } from 'gatsby'

import buildYourPackage from '../../images/svg/pricing/build-your-package.svg'
import selection from '../../images/svg/pricing/selection.svg'
import review from '../../images/svg/pricing/review.svg'

import SiteLayout from '../../layout/SiteLayout'
import SEO from '../../components/SEO'
import Section from '../../components/Section'
import TimelineStep from '../../components/Timeline'
import { FAQ, Question, Answer } from '../../components/FAQ'
import { Title, TitleContainer, TitleIntro } from '../../components/Title'
import { Intro, IntroTitle, IntroText } from '../../components/Intro'
import { PricingPlanTable, PricingPlan } from '../../components/PricingPlan'

const SmallBusinessAccounting = () => {
  return (
    <SiteLayout header="light">
      <SEO title="Pricing" />
      <TitleContainer>
        <Title>Small Business Accounting Pricing</Title>
        <TitleIntro>
          For a competitive fixed monthly fee you get unlimited advice from your
          own dedicated accountant, easy-to-use market-leading software and full
          HMRC representation. We're modernising accounting.
        </TitleIntro>
      </TitleContainer>
      <Section>
        <Intro>
          <IntroTitle>Building your package</IntroTitle>
          <IntroText>
            Your business is unique so your accounting service should be too.
            With AirAccounting you build your accounting package based on the
            services you need.
          </IntroText>
        </Intro>
        <TimelineStep
          img={buildYourPackage}
          imgAlt="Business information"
          count="01"
          heading="Enter your business information"
          lineEnd
        >
          <p>
            Provide us with some business information such as business type,
            estimated annual turnover and number of employees. We'll use this
            information to check if our service is right for your business and
            to provide you with an online quote.
          </p>
        </TimelineStep>
        <TimelineStep
          img={selection}
          imgAlt="Select your services"
          heading="Select the services you need"
          count="02"
          lineStart
          lineEnd
          reverse
        >
          <p>
            This is where you tailor the service to your needs. We offer year
            end accounts and tax returns as standard, but you can choose to add
            services such as payroll and VAT returns if you wish.
          </p>
        </TimelineStep>
        <TimelineStep
          img={review}
          imgAlt="Review your quote"
          count="03"
          heading="Review your package and quote"
          lineStart
        >
          <p>
            We'll email your package and quote based on the information you
            provided. Our team will be in touch to see if you need any questions
            answering and to see if you would like to proceed.
          </p>
        </TimelineStep>
      </Section>
      <Section color="gray" bubbles>
        <Intro>
          <IntroTitle>Choose your business type</IntroTitle>
          <IntroText>
            Each of our plans come with our full support service plus the core
            services relevant to your business type. You can then choose add-on
            services depending on your requirements.
          </IntroText>
        </Intro>
        <PricingPlanTable>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Sole trader"
            planPrice="£25.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Sole trader accounts',
              'Self-assessment tax return',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/how-it-works/small-businesses"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Limited company"
            planPrice="£79.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Limited company setup',
              'Limited company accounts',
              'Corporation Tax return',
              'Payroll for 2 directors',
              'Tax return for 2 directors',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
              'Company secretarial',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/how-it-works/small-businesses"
            >
              Learn more
            </Link>
          </PricingPlan>
          <PricingPlan
            planType="Small Business Accounting"
            planName="Partnership"
            planPrice="£48.50"
            planPeriod="+ VAT per month"
            planStandardServices={[
              'Tax registrations',
              'Unlimited advice',
              'HMRC representation',
              'Partner network',
              'Training',
              'Partnership accounts',
              'Tax return for 2 partners',
            ]}
            planAddOnServices={[
              'Payroll for staff',
              'VAT returns',
              'CIS returns',
            ]}
          >
            <Link
              className="btn btn-primary d-block mx-auto"
              to="/pricing/build-your-package"
            >
              Build your package
            </Link>
            <Link
              className="btn btn-link my-3 d-block"
              to="/how-it-works/small-businesses"
            >
              Learn more
            </Link>
          </PricingPlan>
        </PricingPlanTable>
      </Section>
      <Section>
        <Intro>
          <IntroTitle>Frequently asked questions</IntroTitle>
        </Intro>
        <FAQ>
          <Question>Do I have to sign a contract?</Question>
          <Answer>
            No, we don’t tie you into any long-term contracts. Should you wish
            to cancel your service all we ask is for 30 days notice so that we
            can meet any immediate deadlines for you, bring your service to an
            end and provide you with any information you need to transfer to
            your next accountant.
          </Answer>
        </FAQ>
        <FAQ>
          <Question>What if I already have an accountant?</Question>
          <Answer>
            Switching to us from another accountant may be easier than you
            think. Once you have built your package and are happy to proceed our
            team will guide you through the steps of changing over to us.{' '}
            <Link to="/how-it-works/switching-accountants">
              Click here to learn more about the process of switching
              accountants
            </Link>
            .
          </Answer>
        </FAQ>
        <FAQ>
          <Question>Can I change my package once I've signed up?</Question>
          <Answer>
            Yes! You can add and remove services to your package at any time.
          </Answer>
        </FAQ>
        <FAQ>
          <Question>What if I change my business type?</Question>
          <Answer>
            Changing your package from a sole trader plan to a limited company
            plan or vice versa is painless. Our team will handle all the setup
            to ensure a smooth transition.
          </Answer>
        </FAQ>
        <FAQ>
          <Question>What if my business grows or gets smaller?</Question>
          <Answer>
            So long as your annual turnover does not exceed £5m and you don't
            have more than 50 employees we can continue to provide our services
            to you. Your monthly fee will change if your annual turnover
            increases or decreases by more than £50,000 or if your number of
            employees changes.
          </Answer>
        </FAQ>
      </Section>
    </SiteLayout>
  )
}

export default SmallBusinessAccounting
