import React from 'react'
import cn from 'classnames'

import styles from './Timeline.module.scss'

const TimelineStep = props => {
  const {
    img,
    imgAlt,
    children,
    heading,
    reverse,
    count,
    lineStart,
    lineEnd,
  } = props

  const className = cn(styles.step, { [styles.reverse]: reverse })

  return (
    <div className={className}>
      {lineStart && <span className={styles.lineStart} />}
      <div className={styles.imgContainer}>
        <img className={styles.img} src={img} alt={imgAlt} />
      </div>
      <div className={styles.counter}>
        <span>{count}</span>
      </div>
      <div className={styles.text}>
        <h5>{heading}</h5>
        {children}
      </div>
      {lineEnd && <span className={styles.lineEnd} />}
    </div>
  )
}

export default TimelineStep
