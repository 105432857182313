import React from 'react'

import styles from './PricingPlan.module.scss'

const PricingPlanTable = ({ children }) => {
  return <div className={styles.pricingPlanTable}>{children}</div>
}

const PricingPlan = props => {
  const {
    planType,
    planName,
    planPrice,
    planPeriod,
    planStandardServices,
    planAddOnServices,
    children,
  } = props

  return (
    <div className={styles.planContainer}>
      <div className={styles.plan}>
        <div className={styles.planHeadline}>
          <p className={styles.planType}>{planType}</p>
          <p className={styles.planName}>{planName}</p>
          <p className={styles.from}>From</p>
          <p className={styles.price}>{planPrice}</p>
          <p className={styles.period}>{planPeriod}</p>
        </div>
        <div className={styles.planFeatures}>
          <h5 className={styles.heading}>Standard services</h5>
          <ul className="bullet-list">
            {planStandardServices.map(serviceText => (
              <li key={serviceText}>{serviceText}</li>
            ))}
          </ul>
          {planAddOnServices.length > 0 && (
            <>
              <h5 className={styles.heading}>Available add-ons</h5>
              <ul className="bullet-list">
                {planAddOnServices.map(serviceText => (
                  <li key={serviceText}>{serviceText}</li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="text-center mt-5">{children}</div>
      </div>
    </div>
  )
}

export { PricingPlanTable, PricingPlan }
